//handling all social media accounts
export function socialMediaBtn(event) {
  let link = null;
  switch (event) {
    case "insta":
      link = "https://www.instagram.com/boynextcubicle/";
      break;
    case "linkedIn":
      link = "https://www.linkedin.com/in/saurav-singh-08/";
      break;
    case "youtube":
      link = "https://www.youtube.com/channel/UC-qMR02vvWOiKAweLKxeifw";
      break;
    case "github":
      link = "https://www.github.com/saurav0896";
      break;
    default:
      link = event;
  }
  window.open(link);
}

//always scroll to top on page load
export function scrollToTop() {
  window.scrollTo({ top: 0, behavior: "auto" });
}
