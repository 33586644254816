<template>
  <div>
    <v-app-bar app flat color="bg" class="navBarDesign elevation-8" fixed>
      <router-link to="/" class="d-flex align-center">
        <v-img
          alt="Brand Logo"
          class="shrink mr-2 mt-1 mb-1 navLogo"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="50"
        />

        <h2 class="primary--text">SrvX</h2>
        <h3 class="ml-1 mt-3 link--text">/{{ this.$store.state.title }}</h3>
      </router-link>

      <!-- <v-spacer></v-spacer>
    <v-btn icon color="primary"
      ><v-icon dark @click="toggleTheme"> mdi-theme-light-dark </v-icon></v-btn
    > -->

      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        v-if="this.$store.state.title == 'Dev'"
        @click="drawer = !drawer"
        class="primary--text d-sm-none"
      ></v-app-bar-nav-icon>
      <router-link
        to="/dev/code-editor"
        v-if="
          this.$vuetify.breakpoint.name != 'xs' &&
          this.$store.state.title == 'Dev'
        "
      >
        <v-btn color="primary" depressed
          ><v-icon class="mr-1">mdi-package-variant-closed</v-icon>Code
          Editor</v-btn
        >
      </router-link>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary color="#111111">
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            alt="Brand Logo"
            class="shrink mr-2 mt-2 ml-2 mb-2 navLogo"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="45"
          />
        </router-link>
        <h2 class="primary--text">SrvX</h2>
        <h3 class="ml-1 mt-3 link--text">/{{ this.$store.state.title }}</h3>
      </div>
      <v-divider class="mb-1"></v-divider>
      <template>
        <TreeView />
      </template>
      <v-footer dark padless class="black" fixed>
        <v-card
          flat
          tile
          class="black white--text d-flex justify-space-around px-5 text-center"
          width="100%"
        >
          <v-btn icon small @click="btnClick('insta')"
            ><v-icon>mdi-instagram</v-icon></v-btn
          >
          <v-btn icon small @click="btnClick('linkedIn')"
            ><v-icon>mdi-facebook</v-icon></v-btn
          >
          <v-btn icon small @click="btnClick('youtube')"
            ><v-icon>mdi-youtube</v-icon></v-btn
          >
          <v-btn icon small @click="btnClick('github')"
            ><v-icon>mdi-github</v-icon></v-btn
          >
        </v-card>
      </v-footer>
    </v-navigation-drawer>
  </div>
</template>
<script>
import TreeView from "@/components/DevTool/TreeView.vue";
import { socialMediaBtn } from "@/helpers/helperFunctions.js";
export default {
  components: {
    TreeView,
  },
  data() {
    return {
      drawer: null,
    };
  },
  methods: {
    btnClick: function (event) {
      socialMediaBtn(event);
    },
  },
};
</script>
