import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "../views/Landing.vue";
// import Portfolio from "@/components/Portfolio/Portfolio.vue";
// import DevTool from "@/components/DevTool/DevTool.vue";
// import Resources from "@/components/DevTool/Resources.vue";
// import QuickAccess from "@/components/DevTool/QuickAccess.vue";
// import CodeEditor from "@/components/DevTool/CodeEditor.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: () => import("@/components/Portfolio/Portfolio.vue"),
  },
  {
    path: "/dev",
    name: "DevTool",
    component: () => import("@/components/DevTool/DevTool.vue"),
    redirect: { name: "resources" },
    children: [
      {
        path: "resources",
        name: "resources",
        component: () => import("@/components/DevTool/Resources.vue"),
        redirect: { name: "quick-access" },
        children: [
          {
            path: "quick-access",
            name: "quick-access",
            component: () => import("@/components/DevTool/QuickAccess.vue"),
          },
          {
            path: "json-formatter",
            name: "json-formatter",
            component: () =>
              import("@/components/DevTool/DeveloperTools/JSONFormatter.vue"),
          },
          {
            path: "escape-unescape",
            name: "escape-unescape",
            component: () =>
              import("@/components/DevTool/DeveloperTools/EscapeUnescape.vue"),
          },
          {
            path: "regex-generator",
            name: "regex-generator",
            component: () =>
              import("@/components/DevTool/DeveloperTools/RegeX.vue"),
          },
          {
            path: "resume-builder",
            name: "resume-builder",
            component: () =>
              import("@/components/BuilderTool/ResumeBuilder.vue"),
            //component: () => import('@/components/BuilderTool/ResumeTemplates/Template3.vue')
          },
          {
            path: "bio-data-builder",
            name: "bio-data-builder",
            component: () =>
              import("@/components/BuilderTool/BioDataBuilder.vue"),
          },
        ],
      },
      {
        path: "/dev/code-editor",
        name: "code-editor",
        component: () => import("@/components/DevTool/CodeEditor.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
