<template>
  <v-footer dark padless>
    <v-card flat tile class="text-center" width="100%">
      <v-card-text class="d-flex">
        <div class="d-flex align-center pl-5">
          <router-link to="/">
            <v-img
              alt="Brand Logo"
              class="shrink mr-2 mt-1 mb-1 navLogo"
              contain
              src="@/assets/logo.png"
              transition="scale-transition"
              width="40"
            />
          </router-link>
          <h2 class="primary--text">SrvX</h2>
        </div>
        <v-spacer></v-spacer>
        <v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon>
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-card-text class="white--text pa-0 footerbg">
        Made in <v-icon color="red">mdi-heart</v-icon> India |&nbsp; &#169;
        2021-2025 Saurav Singh
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>
<style scoped>
.footerbg {
  background-color: black;
  letter-spacing: 0.1rem;
}
</style>
