<template>
  <v-app>
    <Header />
    <router-view />
    <Footer />
  </v-app>
</template>

<script>
import Header from "../src/views/Header.vue";
import Footer from "../src/views/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data: () => ({
    isDark: false,
    //
  }),
  methods: {
    themeChange() {
      this.isDark = !this.isDark;
      this.$vuetify.theme.dark = this.isDark;
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
</style>
