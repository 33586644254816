<template>
  <v-card
    class="mx-auto"
    flat
    color="#111111"
    max-width="500"
    style="border-radius: 0px !important"
  >
    <router-link to="/dev/code-editor" class="d-flex justify-end d-sm-none">
      <v-btn color="primary" outlined class="mb-1 mr-1" small depressed
        ><v-icon class="mr-1" small>mdi-package-variant-closed</v-icon>Code
        Editor</v-btn
      >
    </router-link>
    <v-divider></v-divider>
    <v-sheet class="pa-3" style="border-radius: 0px !important">
      <v-text-field
        v-model="search"
        label="Search Editor"
        flat
        background-color="#111111"
        dark
        color="primary"
        dense
        class="pt-2"
        outlined
        hide-details
        clearable
        clear-icon="mdi-close"
      ></v-text-field>
      <v-checkbox
        v-model="caseSensitive"
        dark
        hide-details
        label="Case sensitive search"
      ></v-checkbox>
    </v-sheet>
    <v-card-text class="pa-0">
      <v-treeview
        :items="items"
        :search="search"
        :filter="filter"
        transition
        :open.sync="open"
      >
        <template v-slot:prepend="{ item }">
          <v-icon v-if="item.icon" color="primary">
            {{ item.icon }}
          </v-icon>
        </template>
        <template v-slot:label="{ item }">
          <span
            v-if="item.link"
            @click="navigate(item)"
            style="cursor: pointer"
          >
            {{ item.name }}
          </span>
          <span v-else>
            {{ item.name }}
          </span>
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      drawer: null,
      items: [
        {
          id: 1,
          name: "Quick Access",
          icon: "mdi-view-dashboard",
          link: "quick-access",
        },
        {
          id: 2,
          name: "Builder Tools",
          icon: "mdi-wrench",
          children: [
            {
              id: 201,
              name: "Resume Builder",
              icon: "mdi-file-tree",
              link: "resume-builder",
            },
            {
              id: 202,
              name: "Matrimonial BioData",
              icon: "mdi-file-tree",
              link: "bio-data-builder",
            },
            {
              id: 203,
              name: "Certificate Builder",
              icon: "mdi-file-tree",
              link: "",
            },
          ],
        },
        {
          id: 3,
          name: "Developer Tools",
          icon: "mdi-code-braces",
          children: [
            {
              id: 301,
              name: "Formatter",
              icon: "mdi-code-tags-check",
              children: [
                {
                  id: 311,
                  name: "JSON Formatter",
                  icon: "mdi-file-tree",
                  link: "json-formatter",
                },
                {
                  id: 312,
                  name: "Escape / Unescape",
                  icon: "mdi-file-tree",
                  link: "escape-unescape",
                },
              ],
            },
            {
              id: 302,
              name: "Text Editor",
              icon: "mdi-format-size",
              children: [
                {
                  id: 321,
                  name: "Escape/Unescape",
                  icon: "mdi-file-tree",
                  link: "",
                },
                {
                  id: 322,
                  name: "RegeX Generator",
                  icon: "mdi-file-tree",
                  link: "",
                },
              ],
            },
          ],
        },
        {
          id: 4,
          name: "Generic Tools",
          icon: "mdi-package-variant-closed",
        },
      ],
      open: [1, 2, 3],
      search: null,
      caseSensitive: false,
    };
  },
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    navigate(item) {
      this.$router.push({ name: item.link });
    },
  },
};
</script>
