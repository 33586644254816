<template>
  <v-main class="landingContainer bg">
    <v-row class="heroContianer">
      <v-col cols="12" lg="7" sm="12" md="7">
        <div class="taglineBox">
          <h1 class="primary--text tagline">All-In-One</h1>
          <h4 class="tagline-caption">
            Exhausted with searching different dev tools ? So this is for you<br /><br />
            <span class="primary--text highlight px-1">SrvX</span> is a
            <span class="primary--text highlight px-1"
              >“open source all-in-one hub”</span
            >
            which brings you all the requried tools at one place and saves a lot
            of your time.
          </h4>
          <span class="secondary--text">
            Resume Builder / JSON Validator / CSS Modifier / Certificate Builder
            /......
          </span>
          <div class="d-flex">
            <router-link to="/portfolio">
              <v-btn tile color="primary" dark> about me </v-btn>
            </router-link>
            <v-card
              flat
              tile
              class="white--text d-flex justify-space-around ml-5 my-2 bannerIcon"
              width="25%"
              style="background: transparent"
            >
              <v-btn icon small class="social-icon" @click="btnClick('insta')"
                ><v-icon>mdi-instagram</v-icon></v-btn
              >
              <v-btn
                icon
                small
                class="social-icon"
                @click="btnClick('linkedIn')"
                ><v-icon>mdi-linkedin</v-icon></v-btn
              >
              <v-btn icon small class="social-icon" @click="btnClick('youtube')"
                ><v-icon>mdi-youtube</v-icon></v-btn
              >
              <v-btn icon small class="social-icon" @click="btnClick('github')"
                ><v-icon>mdi-github</v-icon></v-btn
              >
            </v-card>
          </div>
          <v-btn flat class="scroll-down-text" href="#tools">SCROLL DOWN</v-btn>
          <div class="scroll-down-button scroll1"></div>
          <div class="scroll-down-button scroll2"></div>
        </div>
      </v-col>
      <v-col cols="12" lg="5" sm="12" md="5">
        <v-img :src="HeroBanner" class="mt-3"> </v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="categoryBox" id="tools">Component Categories</div>
        <div class="showOptionBoxes">
          <router-link to="/portfolio">
            <v-card class="box d-flex elevation-8">
              <v-avatar size="125" tile class="ma-3">
                <v-img :src="portfolioImg" contain> </v-img>
              </v-avatar>
              <div>
                <v-card-title class="primary--text pb-1"
                  >My Portfolio</v-card-title
                >
                <v-card-text class="subtitle"
                  >Explore more about me and my work. <br />
                  <v-btn color="primary" class="mt-2" outlined tile small
                    >Explore</v-btn
                  >
                </v-card-text>
              </div>
            </v-card>
          </router-link>
          <router-link to="/dev">
            <v-card class="box d-flex elevation-8">
              <v-avatar size="125" tile class="ma-3">
                <v-img :src="DevPortal" contain> </v-img>
              </v-avatar>
              <div>
                <v-card-title class="primary--text pb-1"
                  >Developer Tools</v-card-title
                >
                <v-card-text class="subtitle"
                  >One Stop for all Developer Tools.<br />
                  <v-btn color="primary" class="mt-2" outlined tile small
                    >Explore</v-btn
                  >
                </v-card-text>
              </div>
            </v-card>
          </router-link>
          <router-link to="/portfolio">
            <v-card class="box d-flex elevation-8">
              <v-avatar size="125" tile class="ma-3">
                <v-img :src="portfolioImg" contain> </v-img>
              </v-avatar>
              <div>
                <v-card-title class="primary--text pb-1"
                  >Blog & Source Code</v-card-title
                >
                <v-card-text class=""
                  >A centeralized hub consisting cheatsheet and source code<br />
                  <v-btn color="primary" class="mt-2" tile outlined small
                    >Explore</v-btn
                  >
                </v-card-text>
              </div>
            </v-card>
          </router-link>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="2"></v-col>
      <v-col cols="12" lg="8">
        <div class="categoryBox elevation-8 mt-8" id="tools">
          Popular Developer Tools
        </div>
        <div class="showOptionBoxes elevation-8 mb-8">
          <router-link to="/dev/resources/resume-builder">
            <v-card class="box d-flex elevation-8 pa-3">
              <v-avatar color="brown" class="pt-2" tile size="48">
                <span class="white--text text-h5">RB</span>
              </v-avatar>
              <v-card-title class="primary--text py-0"
                >Resume Builder</v-card-title
              >
            </v-card>
          </router-link>
          <router-link to="/dev/resources/json-formatter">
            <v-card class="box d-flex elevation-8 pa-3">
              <v-avatar color="brown" class="pt-2" tile size="48">
                <span class="white--text text-h5">JF</span>
              </v-avatar>
              <v-card-title class="primary--text py-0"
                >JSON Formatter</v-card-title
              >
            </v-card>
          </router-link>
          <router-link to="/dev/resources/bio-data-builder">
            <v-card class="box d-flex elevation-8 pa-3">
              <v-avatar color="brown" class="pt-2" tile size="48">
                <span class="white--text text-h5">BD</span>
              </v-avatar>
              <v-card-title class="primary--text py-0"
                >Bio Data Builder</v-card-title
              >
            </v-card>
          </router-link>
          <router-link to="/dev/resources/resume-builder">
            <v-card class="box d-flex elevation-8 pa-3">
              <v-avatar color="brown" class="pt-2" tile size="48">
                <span class="white--text text-h5">CF</span>
              </v-avatar>
              <v-card-title class="primary--text py-0"
                >Certificate Builder</v-card-title
              >
            </v-card>
          </router-link>
          <router-link to="/dev/code-editor">
            <v-card class="box d-flex elevation-8 pa-3">
              <v-avatar color="brown" class="pt-2" tile size="48">
                <span class="white--text text-h5">CE</span>
              </v-avatar>
              <v-card-title class="primary--text py-0"
                >Code Editor</v-card-title
              >
            </v-card>
          </router-link>
          <router-link to="/dev/resources/resume-builder">
            <v-card class="box d-flex elevation-8 pa-3">
              <v-avatar color="brown" class="pt-2" tile size="48">
                <span class="white--text text-h5">RB</span>
              </v-avatar>
              <v-card-title class="primary--text py-0"
                >RegX Builder</v-card-title
              >
            </v-card>
          </router-link>
          <router-link to="/dev/resources/resume-builder">
            <v-card class="box d-flex elevation-8 pa-3">
              <v-avatar color="brown" class="pt-2" tile size="48">
                <span class="white--text text-h5">TF</span>
              </v-avatar>
              <v-card-title class="primary--text py-0"
                >Text Formatter</v-card-title
              >
            </v-card>
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" lg="2"></v-col>
    </v-row>
  </v-main>
</template>

<script>
import portfolioImg from "@/assets/profileImg.svg";
import DevPortal from "@/assets/DevPortal.svg";
import HeroBanner from "@/assets/heroBanner.png";
import { socialMediaBtn } from "@/helpers/helperFunctions.js";
export default {
  data() {
    return {
      portfolioImg,
      DevPortal,
      HeroBanner,
    };
  },
  metaInfo: {
    title: "SrvX - Landing | Portfolio | Blogs | Dev Tools | Code Editor",
    meta: [
      {
        vmid: "landing",
        name: "description",
        content:
          "A one stop platform for variety of developer tools , builder platform and a code environment. This website is developed to provide all required sevices under one umbrella.",
      },
    ],
  },
  mounted() {
    this.$store.state.title = "All-In-One";
  },
  methods: {
    btnClick: function (event) {
      socialMediaBtn(event);
    },
  },
};
</script>
<style>
.landingContainer {
  position: relative;
}
.heroContianer {
  padding: 5%;
}

.taglineBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10%;
  /* justify-content: center; */
  position: relative;
}
.highlight {
  font-weight: bold;
}
.tagline {
  font-family: "Kaushan Script", cursive;
  font-size: 4em;
}
.tagline-caption {
  font-family: "Manrope", sans-serif;
  font-size: 1.3em;
  font-weight: normal;
  margin: 1% 0 3% 0;
}

.showOptionBoxes {
  display: flex;
  width: 100%;
  height: max-content;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: space-evenly;
  background-color: #191919;
  padding: 3%;
}
.categoryBox {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #191919;
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.8rem;
  padding-top: 1%;
}
.box {
  width: max-content;
  max-width: 23em;
  height: max-content;
}
/* .cardImg {
  height: 50%;
  width: 50%;
} */
.box:hover {
  box-shadow: 0px 0px 20px #ff6700 !important;
}
.scroll-down-button {
  width: 2em;
  height: 2em;
  background-color: transparent;
  z-index: 80;
  position: absolute;
  border-width: 0 0.25em 0.25em 0;
  border-style: solid;
  border-color: antiquewhite;
  left: 50%;
  transform: translateY(0%) rotate(45deg);
  opacity: 0;
}

.scroll-down-text {
  width: max-content;
  height: max-content;
  background-color: transparent;
  z-index: 80;
  position: absolute;
  border-style: solid;
  border-color: antiquewhite;
  color: antiquewhite;
  left: 50%;
  bottom: 8%;
  padding: 0.8rem 1rem;
  transform: translateX(-40%);
  opacity: 0.4;
}

.scroll1 {
  bottom: 2%;
  animation: scrolldown 1.2s ease-in-out infinite 0.15s;
}

.scroll2 {
  bottom: 4%;
  animation: scrolldown 1.2s ease-in-out infinite;
}

@keyframes scrolldown {
  0% {
    transform: translateY(20%) rotate(45deg);
    opacity: 0.7;
  }
  50% {
    transform: translateY(0%) rotate(45deg);
    opacity: 0.2;
  }
  100% {
    transform: translateY(20%) rotate(45deg);
    opacity: 0.7;
  }
}

@media screen and (max-width: 740px) {
  .showOptionBoxes {
    flex-direction: column;
    width: 100%;
    height: max-content;
    justify-content: center;
    align-items: center;
  }
  .categoryBox {
    font-size: 1.5rem;
    padding-bottom: 5%;
  }
  .taglineBox {
    padding-bottom: 6em;
  }
  .tagline {
    font-size: 2.5em;
  }
  .tagline-caption {
    font-size: 1em;
  }
}
/* @media screen and (min-width: 740px) and (max-width: 1400px) {
  .cardImg {
    height: 50%;
    width: 50%;
  }
} */
</style>
